import { graphql } from "gatsby"
import React from "react"
import { slugify } from "../../../utils"
import * as styles from "./DatoIframeBlock.module.scss"

const DatoIframeBlock = (props: Queries.DatoIframeBlockFragment) => {
  //console.log('Rendering DatoIframeBlock', props)

  if (!props.embedSource) {
    return null
  }

  // TODO i18n "Embedded content"

  const fullWidth = props.layout == "FullWidth" || props.layout == "Tile"

  return (
    <div
      id={slugify(props.title, props.title ? "" : props.embedSource)}
      className={`${styles.container} ${fullWidth ? styles.fullWidth : "layout-container"}`}>
      {props.title && <h2 className="layout-container">{props.title}</h2>}
      <iframe
        title={props.title || `Embedded content: ${props.embedSource}`}
        src={props.embedSource}
        style={props.height ? { minHeight: props.height } : undefined}
      />
    </div>
  )
}

export default DatoIframeBlock

export const query = graphql`
  fragment DatoIframeBlock on DatoCmsIframeBlock {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    embedSource
    height
    layout
  }
`
